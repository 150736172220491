/*=========================================================================================
  File Name: moduleEmailState.js
  Description: Email Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  survey: [],
  botService: '',
  senderId: '',
  surveyService: '',
  surveyId: '',
  theme: {
    colors: {
      danger: '',
      dark: '',
      primary: '',
      success: '',
      warning: ''
    },
    companyLogo: ''
  }
}
