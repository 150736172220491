import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

const sentryEnv = window.config.sentryEnv
console.log('Sentry Env::', sentryEnv)
const isProduction = sentryEnv === 'production'
const isDevelopment = sentryEnv === 'development'

if (isProduction) {
  const sentryData = {
    key: '35ef2a987f284ca9825526138c4e1fcd',
    url: 'sentry-pd.kona.tech',
    project: '31'
  }

  Sentry.init({
    dsn: `https://${sentryData.key}@${sentryData.url}/${sentryData.project}`,
    environment: sentryEnv,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
        logErrors: !isProduction && !isDevelopment
      })
    ]
  })
}
