/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/survey'
        },
        {
          path: '/survey',
          name: 'dashboard-survey',
          component: () => import('./views/survey/survey.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/:uniqid',
          name: 'dashboard-survey',
          component: () => import('./views/survey/survey-short-url.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/survey/:uniqid',
          name: 'dashboard-survey',
          component: () => import('./views/survey/survey-short-url.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    {
      path: '/pages/error-404',
      name: 'page-error-404',
      component: () => import('@/views/pages/Error404.vue'),
      meta: {
        rule: 'editor'
      }
    },
    {
      path: '/pages/error-500',
      name: 'page-error-500',
      component: () => import('@/views/pages/Error500.vue'),
      meta: {
        rule: 'editor'
      }
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  return next()
})

export default router
