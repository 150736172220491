/*=========================================================================================
  File Name: moduleEmailActions.js
  Description: Email Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  // Fetch emails
  fetchUniqid ({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/survey-response/${params.uniqid}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSurveyById ({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/survey/${params.surveyId}/active-survey-array/${params.uniqid}?senderId=${params.senderId}&botService=${params.botService}&surveyService=${params.surveyService}`
        )
        .then((response) => {
          commit('SET_SURVEY', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchSurveySettings ({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/survey/${params.surveyId}/active-survey-array-company?senderId=${params.senderId}&botService=${params.botService}&surveyService=${params.surveyService}`
        )
        .then((response) => {
          commit('SET_SURVEY_SETTINGS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setupSurveyParams (
    { commit, state },
    { senderId, botService, surveyService }
  ) {
    commit('SET_SURVEY_ID', surveyService)
    commit('SET_SENDER_ID', senderId)
    commit('SET_BOT_SERVICE', botService)
  },
  sendSurveyResponse ({ commit, state }, response) {
    return new Promise((resolve, reject) => {
      axios
        .post('/survey-response', {
          senderId: state.senderId,
          botService: state.botService,
          surveyService: state.surveyId,
          responses: response
        })
        .then((response) => {
          console.log('RESPONSE')
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}
