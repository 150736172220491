// axios

import axios from 'axios'

const baseURL = 'https://dev.konecta.qa.kona.tech'

export default axios.create({
  // headers: {
  //   Authorization:
  //     "Bearer eyJhbGciOiJIUzI1NiJ9.NWM4OTQ2NmZlOGE4ZDIwMDVkYmEzZGUw.6BPbytESxbVMYcDWhzmsjibeDzq881E_jIx4NfvF7LY",
  // },
  // You can add your headers here
})
