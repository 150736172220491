/*=========================================================================================
  File Name: moduleEmailMutations.js
  Description: Email Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_SURVEY (state, survey) {
    survey.forEach(({
      key
    }) => {
      if (key != undefined) survey[key] = ''
    })
    state.survey = survey
  },
  SET_SURVEY_SETTINGS (state, settings) {
    console.log(settings)
    state.theme = settings.theme
    if (state.theme.companyLogo == undefined || !state.theme.companyLogo) {
      state.theme.companyLogo = require('../../assets/images/logo/logo.svg')
    }
    if (state.theme.colors == undefined || !state.theme.colors) {
      state.theme.colors = {
        primary: 'rgb(255, 129, 0)', // linear-gradient(rgb(255, 0, 61), rgb(255, 94, 0))
        success: 'rgb(40, 199, 111)',
        danger: 'rgb(234, 84, 85)',
        warning: 'rgb(255, 159, 67)',
        dark: 'rgb(0, 0, 0)'
      }
    }
    const favicon = document.getElementById('favicon')
    favicon.href = state.theme.companyLogo
  },
  SET_SURVEY_ID (state, surveyId) {
    state.surveyId = surveyId
  },
  SET_SENDER_ID (state, senderId) {
    state.senderId = senderId
  },
  SET_BOT_SERVICE (state, botService) {
    state.botService = botService
  }
}
