/*=========================================================================================
  File Name: moduleEmailGetters.js
  Description: Email Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  getSurvey: (state) => {
    let response = []
    if (state.survey) {
      response = [...state.survey]
      response.pop()
    }
    return response
  },
  getSurveyTheme: (state) => {
    return state.theme
  },
  getFinalMessage: (state) => {
    let response = {}
    if (state.survey) {
      response = [...state.survey]
      response = response.pop()
      //response.question = [response.question[0], response.question[0]];
    }
    return response
  }
}
